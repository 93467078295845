import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import SingleColumnText from '../components/SingleColumnText'
import LocaleContext from '../contexts/LocaleContext'
import ContactUs from '../components/ContactUs'
import ResponsiveEmbed from 'react-responsive-embed'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import Hero from '../components/Hero'
import ShorterContent from '../components/ShorterContent'
import Footer from '../components/Footer'
import Menu from '../components/Menu'

export const query = graphql`
  query($slug: String!, $locale: String!) {
    datoCmsPost(slug: { eq: $slug }, locale: { eq: $locale }) {
      title
      author
      date
      postType
      eventTime
      eventPlace
      heroImage {
        fluid(maxWidth: 1500) {
          ...GatsbyDatoCmsFluid
        }
      }
      video {
        providerUid
        width
        height
        provider
      }
      bodyNode {
        childMarkdownRemark {
          html
        }
      }
    }
    datoCmsContact (locale: { eq: $locale }){
      ...Contact
    }
    datoCmsNewMenu (locale: { eq: $locale }){
      ...Menu
    }
    datoCmsFooter (locale: { eq: $locale }){
      ...Footer
    }
  }
`

function SinglePost({
  data: { datoCmsPost, datoCmsContact, datoCmsFooter, datoCmsNewMenu },
  pageContext,
}) {
  return (
    <LocaleContext.Provider value={pageContext.locale}>
      <Layout newsletter={pageContext.postType !== 'press'} 
              locale={pageContext.locale}>
      <Menu
          transparent={false}
          menu={datoCmsNewMenu}
          socialMedia={datoCmsFooter.socialMedia}
        />
        <React.Fragment>
          <HelmetDatoCms seo={datoCmsPost.seoMetaTags} />

          {datoCmsPost.heroImage && (
            <Hero
              className="hero--small"
              heroBackground={datoCmsPost.heroImage}
            />
          )}

          <div className="container center-width">
            <ShorterContent>
              <article className="prose">
                {datoCmsPost.title && <h1>{datoCmsPost.title}</h1>}
                {datoCmsPost.postType === 'event' ? (
                  <div className="margin-top-small">
                    {datoCmsPost.eventTime && (
                      <p className="unimp small">
                        <strong>
                          <FontAwesomeIcon
                            icon={faMapMarkerAlt}
                            className="margin-right-text"
                          />{' '}
                          {new Date(datoCmsPost.eventTime).toLocaleString()}
                        </strong>
                      </p>
                    )}
                    {datoCmsPost.eventPlace && (
                      <p className="unimp small">
                        <strong>
                          <FontAwesomeIcon
                            icon={faClock}
                            className="margin-right-text"
                          />{' '}
                          {datoCmsPost.eventPlace}
                        </strong>
                      </p>
                    )}
                  </div>
                ) : (
                  <div className="margin-top-small">
                    {datoCmsPost.date && (
                      <span className="unimp margin-right-big">
                        {datoCmsPost.date}
                      </span>
                    )}
                    {datoCmsPost.author && <span>{datoCmsPost.author}</span>}
                  </div>
                )}

                <div className="margin-top-small">

                  <Follow />
                  {datoCmsFooter.socialMedia.map(link => (
                    <a
                      href={link.target}
                      target="_blank"
                      rel="noreferrer"
                      className="margin-left-small icon"
                      key={link.target}
                    >
                      <img src={link.icon.url} alt={link.name} />
                    </a>
                  ))}
                </div>

                {datoCmsPost.video &&
                  datoCmsPost.video.provider === 'youtube' && (
                    <ResponsiveEmbed
                      src={`https://www.youtube.com/embed/${datoCmsPost.video.providerUid}`}
                      frameborder="0"
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
                      allowfullscreen
                    ></ResponsiveEmbed>
                  )}

                {datoCmsPost.bodyNode.childMarkdownRemark.html && (
                  <SingleColumnText
                    className="padding-bottom margin-top"
                    html={datoCmsPost.bodyNode.childMarkdownRemark.html}
                  />
                )}
              </article>

              <div className="margin-top">
                <div>
                  <Follow />
                  {datoCmsFooter.socialMedia.map(link => (
                    <a
                      href={link.target}
                      target="_blank"
                      rel="noreferrer"
                      className="margin-left-small icon"
                      key={link.target}
                    >
                      <img src={link.icon.url} alt={link.name} />
                    </a>
                  ))}
                </div>
              </div>
            </ShorterContent>
          </div>

          <div className="container center-width">
            <ShorterContent>
              <ContactUs data={datoCmsContact} />
            </ShorterContent>
          </div>
          <Footer footer={datoCmsFooter} />
        </React.Fragment>
      </Layout>
    </LocaleContext.Provider>
  )
}


const Follow = () => {
  const locale = React.useContext(LocaleContext).language
  return (
    <span>
      {locale=="sv" ? "Följ oss på: " : "Follow us on:" }
    </span>
  )
}

export default SinglePost
