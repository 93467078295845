import React from 'react'

function SingleColumnText({ html, id, className }) {
  return (
    <section className={className} id={id}>
      <div className="prose" dangerouslySetInnerHTML={{ __html: html }} />
    </section>
  )
}

export default SingleColumnText
